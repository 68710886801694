import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Login";
import TokenDistribution from "../pages/TokenDistribution";
import { useFirebase } from "../context/firebase/useFirebaseProvider";

const Redirect = ({ to }) => {
  return <Navigate to={to} />;
};

const AppRoutes = () => {
  const { isAuthReady, isLoggedIn } = useFirebase();

  return (
    <>
      <Routes>
        {isAuthReady && isLoggedIn && (
          <>
            <Route path="/distribute-token" element={<TokenDistribution />} />
            <Route path="*" element={<Redirect to="/distribute-token" />} />
          </>
        )}
        {isAuthReady && !isLoggedIn && (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Redirect to="/" />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default AppRoutes;
