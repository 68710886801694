import { getAuth } from "firebase/auth";
import { config } from "../../config";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: config.APIKey,
  authDomain: config.AuthDomain,
  storageBucket: config.storageBucket,
  projectId: config.ProjectId,
  messagingSenderId: config.MessagingSenderId,
  appId: config.AppId,
  measurementId: config.MeasurementId,
};

//init firebase
initializeApp(firebaseConfig);

//init firestore
const db = getFirestore();

const storage = getStorage();

const auth = getAuth();

export { db, auth, storage };
