import React, { useContext, createContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "./firebase";
import { useNavigate } from "react-router-dom";

const FireBaseContext = createContext();

const FirebaseProvider = ({ children }) => {
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        console.log(currentUser.accessToken, "current user");
        let isAdmin = await currentUser.getIdTokenResult();
        console.log(isAdmin.claims.organisation, "is admin");
        if (isAdmin.claims.organisation) {
          setIsLoggedIn(true);
          navigate("/distribute-token");
        } else {
          signOut(auth);
        }
      } else {
        setIsLoggedIn(false);
        signOut(auth);
      }
      setIsAuthReady(true);
    });

    return () => unSubscribe();
  }, []);
  const signInUser = async (email, password) => {
    const response = await signInWithEmailAndPassword(auth, email, password);
    if (!response) {
      throw new Error("Could not complete signin");
    }
    return response;
  };

  return (
    <FireBaseContext.Provider value={{ signInUser, isAuthReady, isLoggedIn }}>
      {children}
    </FireBaseContext.Provider>
  );
};

export default FirebaseProvider;

export const useFirebase = () => useContext(FireBaseContext);
