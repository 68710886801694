import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { auth } from "../../context/firebase/firebase";
import axios from "axios";

const StatusModal = ({ isOpen, handleClose, requestId }) => {
  const [requestStatus, setRequestStatus] = useState();

  const getRequestStatus = useCallback(async (requestId) => {
    let token = auth?.currentUser?.accessToken;
    if (requestId) {
      try {
        const response = await axios.get(
          `https://us-central1-capx-x-web3auth.cloudfunctions.net/sdk/requestInfo?request_id=${requestId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRequestStatus(response?.data?.result?.requestInfo?.request_details);
        console.log(response?.data?.result?.requestInfo?.request_details);
      } catch (error) {
        console.log(error, "get request status error");
      }
    }
  }, []);

  useEffect(() => {
    getRequestStatus(requestId);
  }, [requestId, isOpen]);
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle className="text-2xl font-bold self-start">
        Status Info
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{`For Request Id : ${requestId}`}</DialogContentText>
        <table className="w-full border border-collapse table-auto mt-5">
          <tbody>
            <tr className="hover:bg-gray-50">
              <td className="border border-slate-300 w-[40%] px-5 py-2 bg-gray-50 font-bold">
                Status
              </td>
              <td className="border border-slate-300 w-[60%] px-5 py-2">
                {requestStatus?.status}
              </td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="border border-slate-300 w-[40%] px-5 py-2 bg-gray-50 font-bold">
                Message
              </td>
              <td className="border border-slate-300 w-[60%] px-5 py-2">
                {requestStatus?.statusText}
              </td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="border border-slate-300 w-[40%] px-5 py-2 bg-gray-50 font-bold">
                Total Request Completed
              </td>
              <td className="border border-slate-300 w-[60%] px-5 py-2">
                {requestStatus?.status_info?.total_request_completed}
              </td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="border border-slate-300 w-[40%] px-5 py-2 bg-gray-50 font-bold">
                Total Request Failed
              </td>
              <td className="border border-slate-300 w-[60%] px-5 py-2">
                {requestStatus?.status_info?.total_request_failed}
              </td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="border border-slate-300 w-[40%] px-5 py-2 bg-gray-50 font-bold">
                Total Request Processed
              </td>
              <td className="border border-slate-300 w-[60%] px-5 py-2">
                {requestStatus?.status_info?.total_request_processed}
              </td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="border border-slate-300 w-[40%] px-5 py-2 bg-gray-50 font-bold">
                Total Request Processing
              </td>
              <td className="border border-slate-300 w-[60%] px-5 py-2">
                {requestStatus?.status_info?.total_request_processing}
              </td>
            </tr>
            <tr className="hover:bg-gray-50">
              <td className="border border-slate-300 w-[40%] px-5 py-2 bg-gray-50 font-bold">
                Total Request Received
              </td>
              <td className="border border-slate-300 w-[60%] px-5 py-2">
                {requestStatus?.status_info?.total_request_received}
              </td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
      <DialogActions>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleClose}
          name="modal-close"
          type="button"
        >
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusModal;
