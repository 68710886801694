import { signOut } from "firebase/auth";
import React, { lazy, useRef, useState } from "react";
import { auth } from "../context/firebase/firebase";
import Papa from "papaparse";
import BigNumber from "bignumber.js";
import axios from "axios";
import Loader from "../components/loader/loader";
import StatusModal from "../components/modal/statusModal";

const TokenDistribution = () => {
  const inputRef = useRef();
  const [csvData, setCsvData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [respData, setRespData] = useState([]);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [stats, setStats] = useState({
    totalNumberOfAddresses: 0,
    totalNumberOfQuests: 0,
    totalRewardAmount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [isBtnDisable, setIsBtnDisable] = useState(false);

  const getStats = (arr) => {
    const numberOfAddresses = arr.length;
    let totalQuests = 0;
    let rewardAmount = 0;
    arr.forEach((el) => {
      totalQuests += el.totalQuests;
      rewardAmount += el.totalAmount;
    });
    setStats({
      totalNumberOfAddresses: numberOfAddresses,
      totalNumberOfQuests: totalQuests,
      totalRewardAmount: rewardAmount,
    });
  };

  const handleModalClose = () => {
    setStatusModalOpen(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          setCsvData(result.data);
          const outputArr = result.data.reduce((acc, current) => {
            const existingEntry = acc.find(
              (entry) => entry.address === current.wallet_address
            );
            if (existingEntry) {
              // Entry exists, update quests, totalQuests, and totalAmount
              existingEntry.quests.push({
                quest_id: current.quest_id,
                timestamp: current.timestamp,
                reward_amount: current.reward_amount,
              });
              existingEntry.totalQuests += 1;
              existingEntry.totalAmount =
                Number(existingEntry.totalAmount) +
                Number(current.reward_amount);
            } else {
              // Entry doesn't exist, create a new one
              acc.push({
                address: current.wallet_address,
                totalQuests: 1,
                totalAmount: Number(current.reward_amount),
                quests: [
                  {
                    quest_id: current.quest_id,
                    timestamp: current.timestamp,
                    reward_amount: current.reward_amount,
                  },
                ],
              });
            }
            return acc;
          }, []);
          getStats(outputArr);
          setTableData(outputArr);
        },
        header: true,
        skipEmptyLines: true,
      });
    }
  };

  const normalizeRewardString = (rewardAmount) => {
    const normaliseReward = new BigNumber(rewardAmount)
      .multipliedBy(Math.pow(10, 18))
      .integerValue()
      .toString(10);
    const actualRewardAmount = new BigNumber(normaliseReward)
      .dividedBy(Math.pow(10, 18))
      .toString(10);
    return actualRewardAmount;
  };

  const sliceArray = (array, sliceSize) => {
    const slicedArray = [];
    for (let i = 0; i < array.length; i += sliceSize) {
      slicedArray.push(array.slice(i, i + sliceSize));
    }
    return slicedArray;
  };

  const distributeToken = async (data) => {
    const token = auth?.currentUser?.accessToken;
    try {
      const response = await axios.post(
        "https://us-central1-capx-x-web3auth.cloudfunctions.net/sdk/submit",
        {
          data: data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.log(error, "token distribution error");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setIsBtnDisable(true);
    if (csvData !== null) {
      csvData.forEach((e, i) => {
        e.reward_amount = normalizeRewardString(e.reward_amount);
      });
      const slicedArray = sliceArray(csvData, 25);
      try {
        slicedArray.forEach(async (e) => {
          const response = await distributeToken(e);
          console.log(response, "token distribution response in for each");
          setRespData((prev) => [...prev, response?.data?.result]);
        });
      } catch (error) {
        console.log(error, "handleSubmit error");
      }
    }
    setLoading(false);
  };

  console.log(respData, "resp data");
  return (
    <>
      <div className="min-w-screen min-h-screen flex flex-col gap-5 justify-start items-center p-10">
        <div className="min-w-full flex justify-end">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={() => {
              signOut(auth);
            }}
          >
            Sign Out
          </button>
        </div>
        <div className="w-full">
          <h4 className="font-bold text-2xl text-left">Upload a CSV to file</h4>

          <div className="flex items-center justify-center w-full mt-5 overflow-y-auto">
            <button
              onClick={() => {
                inputRef.current.click();
              }}
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                name="dropzone-file"
                ref={inputRef}
                type="file"
                accept=".csv"
                className="hidden"
                onChange={handleFileChange}
              />
            </button>
          </div>
          <div className="w-full mt-5 max-h-[500px] overflow-auto border border-slate-300">
            <table className="w-full border border-collapse border-slate-400">
              <thead>
                <tr className="bg-gray-50">
                  <th className="border border-slate-300 px-5 py-2">
                    Wallet Address
                  </th>
                  <th className="border border-slate-300 px-5 py-2">
                    Total Quests
                  </th>
                  <th className="border border-slate-300 px-5 py-2">
                    Total Reward
                  </th>
                  <th className="border border-slate-300 px-5 py-2">
                    Quest ID
                  </th>
                  <th className="border border-slate-300 px-5 py-2">
                    Quest Reward
                  </th>
                  <th className="border border-slate-300 px-5 py-2">
                    Time Stamp
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 &&
                  tableData.map((el) => {
                    return el.quests.map((quest, i) => {
                      return (
                        <tr className="hover:bg-gray-50">
                          {i === 0 && (
                            <>
                              <td
                                rowSpan={el.totalQuests}
                                className="border border-slate-300 px-5 py-2"
                              >
                                {el.address}
                              </td>
                              <td
                                rowSpan={el.totalQuests}
                                className="border border-slate-300 px-5 py-2"
                              >
                                {el.totalQuests}
                              </td>
                              <td
                                rowSpan={el.totalQuests}
                                className="border border-slate-300 px-5 py-2"
                              >
                                {el.totalAmount}
                              </td>
                            </>
                          )}
                          <td className="border border-slate-300 px-5 py-2">
                            {quest.quest_id}
                          </td>
                          <td className="border border-slate-300 px-5 py-2">
                            {quest.reward_amount}
                          </td>
                          <td className="border border-slate-300 px-5 py-2">
                            {quest.timestamp}
                          </td>
                        </tr>
                      );
                    });
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-full min-h-[22px] flex flex-row flex-wrap gap-5 bg-gray-100 border rounded-xl px-6 py-3">
          <p>
            Total No. of Wallet Addresses :{" "}
            <span className="font-bold">{stats.totalNumberOfAddresses}</span>
          </p>
          <p>
            Total No. of Quests :{" "}
            <span className="font-bold">{stats.totalNumberOfQuests}</span>
          </p>
          <p>
            Total Reward Amount :{" "}
            <span className="font-bold">{stats.totalRewardAmount} </span>
          </p>
        </div>
        <div className="min-w-full flex justify-end">
          <button
            disabled={
              csvData.length === 0 || respData.length > 0 || isBtnDisable
            }
            onClick={handleSubmit}
            className="bg-green-500 hover:bg-green-700 disabled:bg-green-300 disabled:cursor-not-allowed text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
          >
            Distribute Token
          </button>
        </div>
        <div className="w-full justify-self-start">
          <table className="table-auto min-w-[50%] border border-collapse border-slate-400">
            <thead>
              <tr className="bg-gray-50">
                <th className="border border-slate-300 px-5 py-2">
                  Request ID
                </th>
                <th className="border border-slate-300 px-5 py-2">Message</th>
                <th className="border border-slate-300 px-5 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Loader />
              ) : (
                respData.length > 0 &&
                respData.map((elem) => {
                  return (
                    <tr className="hover:bg-gray-50">
                      <td className="border border-slate-300 px-5 py-2">
                        {elem.requestId}
                      </td>
                      <td className="border border-slate-300 px-5 py-2">
                        {elem.message}
                      </td>
                      <td className="border border-slate-300 px-5 py-2">
                        <button
                          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                          onClick={() => {
                            setRequestId(elem.requestId);
                            setStatusModalOpen(true);
                          }}
                        >
                          Status
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
      <StatusModal
        isOpen={statusModalOpen}
        handleClose={handleModalClose}
        requestId={requestId}
      />
    </>
  );
};

export default TokenDistribution;
