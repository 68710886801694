import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFirebase } from "../context/firebase/useFirebaseProvider";
import { auth } from "../context/firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  //   const { signInUser } = useFirebase();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/,
          "Invalid email adress"
        ),
      password: Yup.string().required("Password is required"),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log(values, "sign in values");
        const response = await signInWithEmailAndPassword(
          auth,
          values.email,
          values.password
        );
        let isAdmin = await response.user.auth.currentUser.getIdTokenResult();
        if (isAdmin.claims?.organisation) {
          toast.success("Successfully Signed In");
          navigate("/distribute-token");
        } else {
          toast.error("You are not Authorized");
          resetForm();
          return;
        }
        console.log(isAdmin, "sign in response");
        resetForm();
      } catch (error) {
        toast.error(error.message);
        console.log(error.message, "sign in error");
      }
    },
  });

  return (
    <div className="min-h-screen min-w-screen flex justify-center items-center">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 min-w-[25%]">
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Email
            </label>
            <input
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="email"
              name="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Password
            </label>
            <input
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              name="password"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
